import Activities from './Activities';
import AgeRange from './AgeRange';
import AmericanExpressCard from './AmericanExpressCard';
import Banknote from './Banknote';
import Book from './Book';
import BoldCalendar from './BoldCalendar';
import BoldClock from './BoldClock';
import Branches from './Branches';
import Calendar from './Calendar';
import Call from './Call';
import CallPhone from './CallPhone';
import CallNow from './CallNow';
import Campaign from './Campaign';
import CampaignList from './CampaignList';
import CampaignType from './CampaignType';
import Cancel from './Cancel';
import CheckCircle from './CheckCircle';
import Checkmark from './Checkmark';
import CheckRounded from './CheckRounded';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Click from './Click';
import ClickSpend from './ClickSpend';
import ClockCircle from './ClockCircle';
import CircleEdit from './CircleEdit';
import CircleInfo from './CircleInfo';
import CircleWarning from './CircleWarning';
import CircleQuestion from './CircleQuestion';
import Coins from './Coins';
import Collapse from './Collapse';
import Connect from './Connect';
import Comment from './Comment';
import Close from './Close';
import Cross from './Cross';
import Cost from './Cost';
import CPC from './CPC';
import CrossCircle from './CrossCircle';
import Cursor from './Cursor';
import Dashboard from './Dashboard';
import Date from './Date';
import DangerCircle from './DangerCircle';
import Desktop from './Desktop';
import DiscoverCard from './DiscoverCard';
import DisplayNetwork from './DisplayNetwork';
import Directions from './Directions';
import DollarCircle from './DollarCircle';
import Dots from './Dots';
import Down from './Down';
import Email from './Email';
import EmptyCircle from './EmptyCircle';
import EmptySquare from './EmptySquare';
import Eye from './Eye';
import ExternalLink from './ExternalLink';
import Export from './Export';
import Facebook from './Facebook';
import FacebookIcon from './FacebookIcon';
import FacebookSquare from './FacebookSquare';
import Folder from './Folder';
import Fire from './Fire';
import Gender from './Gender';
import GlobeWeb from './GlobeWeb';
import Google from './Google';
import GoogleLens from './GoogleLens';
import GoogleIcon from './GoogleIcon';
import Gmail from './Gmail';
import Home from './Home';
import Impression from './Impression';
import Image from './Image';
import ImageUpload from './ImageUpload';
import InformationCircle from './InformationCircle';
import Instagram from './Instagram';
import IntstagramText from './InstagramText';
import InstagramSave from './InstagramSave';
import InstagramShare from './InstagramShare';
import InstagramLike from './InstagramLike';
import InstagramComment from './InstagramComment';
import InstagramIcon from './InstagramIcon';
import InstagramVisit from './InstagramVisit';
import Interest from './Interest';
import Language from './Language';
import LeadGen from './LeadGen';
import Letter from './Letter';
import Location from './Location';
import Logo from './Logo';
import LogoIcon from './LogoIcon';
import Logout from './Logout';
import LogoVertical from './LogoVertical';
import LocationPin from './LocationPin';
import Like from './Like';
import Magnifyer from './Magnifyer';
import MapPoint from './MapPoint';
import MastercardCard from './MastercardCard';
import Maps from './Maps';
import MediaLibrary from './MediaLibrary';
import Message from './Message';
import Menu from './Menu';
import Messenger from './Messenger';
import Mobile from './Mobile';
import Notification from './Notification';
import NotificationBell from './NotificationBell';
import OutlineCircle from './OutlineCircle';
import Pencil from './Pencil';
import Person from './Person';
import Phone from './Phone';
import Platform from './Platform';
import PlatformShare from './PlatformShare';
import Plus from './Plus';
import PlusCircle from './PlusCircle';
import QuestionMark from './QuestionMark';
import RadioCheck from './RadioCheck';
import Refresh from './Refresh';
import Save from './Save';
import Search from './Search';
import Send from './Send';
import Settings from './Settings';
import Share from './Share';
import Spend from './Spend';
import SocialMedia from './SocialMedia';
import SortIcon from './SortIcon';
import Star from './Star';
import Store from './Store';
import Templates from './Templates';
import Trash from './Trash';
import TreeDot from './TreeDot';
import TurkeyFlag from './TurkeyFlag';
import UnConnect from './UnConnect';
import User from './User';
import VerticalDots from './VerticalDots';
import VisaCard from './VisaCard';
import Video from './Video';
import Youtube from './Youtube';
import Web from './Web';
import Website from './Website';
import Whatsapp from './Whatsapp';
import XMark from './XMark';
import Wallet from './Wallet';
import Password from './Password';
import EmailSolid from './EmailSolid';
import EyeClosed from './EyeClosed';
import CreditCard from './CreditCard';
import UserSolid from './UserSolid';
import EyeSolid from './EyeSolid';
import EyeClosedAlt from './EyeClosedAlt';
import InstagramColor from './InstagramColor';

export default {
  Activities,
  AgeRange,
  AmericanExpressCard,
  Banknote,
  Book,
  BoldCalendar,
  BoldClock,
  Branches,
  Calendar,
  Call,
  CallPhone,
  CallNow,
  Campaign,
  CampaignList,
  CampaignType,
  Cancel,
  CheckCircle,
  Checkmark,
  CheckRounded,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Click,
  ClickSpend,
  ClockCircle,
  CircleEdit,
  CircleInfo,
  CircleWarning,
  CircleQuestion,
  Coins,
  Collapse,
  Connect,
  Comment,
  Close,
  Cross,
  Cost,
  CPC,
  CrossCircle,
  Cursor,
  Dashboard,
  Date,
  DangerCircle,
  Desktop,
  DiscoverCard,
  DisplayNetwork,
  Directions,
  DollarCircle,
  Dots,
  Down,
  Email,
  EmptyCircle,
  EmptySquare,
  Eye,
  ExternalLink,
  Export,
  Facebook,
  FacebookIcon,
  FacebookSquare,
  Folder,
  Fire,
  Gender,
  GlobeWeb,
  Google,
  GoogleLens,
  GoogleIcon,
  Gmail,
  Home,
  Impression,
  Image,
  ImageUpload,
  InformationCircle,
  Instagram,
  IntstagramText,
  InstagramSave,
  InstagramShare,
  InstagramLike,
  InstagramComment,
  InstagramIcon,
  InstagramVisit,
  Interest,
  Language,
  LeadGen,
  Letter,
  Location,
  Logo,
  LogoIcon,
  Logout,
  LogoVertical,
  LocationPin,
  Like,
  Magnifyer,
  MapPoint,
  MastercardCard,
  Maps,
  MediaLibrary,
  Message,
  Menu,
  Messenger,
  Mobile,
  Notification,
  NotificationBell,
  OutlineCircle,
  Pencil,
  Person,
  Phone,
  Platform,
  PlatformShare,
  Plus,
  PlusCircle,
  QuestionMark,
  RadioCheck,
  Refresh,
  Save,
  Search,
  Send,
  Settings,
  Share,
  Spend,
  SocialMedia,
  SortIcon,
  Star,
  Store,
  Templates,
  Trash,
  TreeDot,
  TurkeyFlag,
  UnConnect,
  User,
  VerticalDots,
  VisaCard,
  Video,
  Youtube,
  Web,
  Website,
  Whatsapp,
  XMark,
  Wallet,
  Password,
  EmailSolid,
  EyeClosed,
  CreditCard,
  UserSolid,
  EyeSolid,
  EyeClosedAlt,
  InstagramColor,
};
