import { array, date as dateLib, currency, interests as interestsLib } from '@app/lib';
import { CAMPAIGN_TYPES, TARGETING_TYPES, GEO_LOCATION_TYPE, CPM_VALUES } from '@app/constants';
import type { Branch as BranchType, Campaign as CampaignType, BranchCampaign as BranchCampaignType } from '@app/api';

function hasLeadGen(campaign: any) {
  return campaign?.targeting?.targeting_type === TARGETING_TYPES.LEAD_GENERATION;
}

function hasPermission(permissions: string[] | undefined, name: string): boolean {
  const value = `targeting_${name}:edit`;

  if (array.isEmpty(permissions)) {
    return false;
  }

  return (permissions as string[]).includes(value);
}

function hasDefaultRange(campaign: any) {
  if (campaign?.targeting?.default_location_range) {
    return true;
  }

  if (campaign?.targeting?.geo_locations?.radius) {
    return true;
  }

  if (campaign?.parent_campaign?.targeting?.default_location_range) {
    return true;
  }

  return false;
}

function defaultCity(branch: any) {
  if (branch?.default_city) {
    return branch?.default_city.name;
  }

  return null;
}

function getCampaignType(campaign: any) {
  const foundedTargetingType = campaign?.targeting?.targeting_type;
  if (foundedTargetingType === TARGETING_TYPES.FINAL_URL) {
    return CAMPAIGN_TYPES.FINAL_URL;
  }

  if (foundedTargetingType === TARGETING_TYPES.MESSENGER) {
    return CAMPAIGN_TYPES.MESSENGER;
  }

  if (foundedTargetingType === TARGETING_TYPES.INSTAGRAM_VISIT) {
    return CAMPAIGN_TYPES.INSTAGRAM_VISIT;
  }

  if (foundedTargetingType === TARGETING_TYPES.WHATSAPP_MESSAGE) {
    return CAMPAIGN_TYPES.WHATSAPP_MESSAGE;
  }

  if (foundedTargetingType === TARGETING_TYPES.LEAD_GENERATION) {
    return CAMPAIGN_TYPES.LEAD_GENERATION;
  }

  return CAMPAIGN_TYPES.CALL;
}

function editFormData(campaign: BranchCampaignType, formData: any) {
  const data = {
    budget: campaign.budget,
    stop_time: campaign?.stop_time ?? null,
    creative: {
      link_data: campaign?.creative?.link_data,
    },
    targeting: {
      gender: campaign.targeting.gender,
      age_min: campaign.targeting.age_min,
      age_max: campaign.targeting.age_max,
      geo_locations: {},
    },
  } as any;

  if (dateLib.isValid(formData?.stopTime)) {
    data.stop_time = formData.stopTime;
  }

  if (formData?.budget) {
    data.budget = String(currency.reverse(formData.budget));
  }

  if (formData?.useAdSchedule) {
    const arrOfNum = formData?.selectedDays?.map((str: string) => {
      return Number(str);
    });

    data.creative = {
      ...data.creative,
      schedule: {
        start_at: dateLib.format(formData.startScheduleHour, 'HH:mm'),
        end_at: dateLib.format(formData.stopScheduleHour, 'HH:mm'),
        days: arrOfNum,
      },
    };
  }

  if (formData?.gender) {
    data.targeting.gender = formData?.gender;
  }

  if (formData?.ageMin) {
    data.targeting.age_min = formData?.ageMin;
  }

  if (formData?.ageMax) {
    data.targeting.age_max = formData?.ageMax;
  }

  if (formData?.interests) {
    data.targeting = {
      ...data.targeting,
      ...interestsLib.groupByType(formData?.interests ?? []),
    };
  } else {
    const targetingData = data.targeting;
    delete targetingData.facebook_lead_gen_form_id;

    data.targeting = {
      ...campaign?.targeting,
      ...targetingData,
    };
  }

  data.targeting.geo_locations = campaign.targeting.geo_locations;

  if (formData.geoLocationType === GEO_LOCATION_TYPE.CITY) {
    if (formData?.cities) {
      data.targeting.geo_locations = {
        cities: formData.cities.map((city: { value: string; name: string; region: string; country_name: string }) => ({
          key: city.value,
          name: city.name,
          region: city.region,
          country_name: city.country_name,
          radius: 0,
          distance_unit: 'kilometer',
        })),
      };
    }
  }

  if (formData.geoLocationType === GEO_LOCATION_TYPE.RANGE) {
    if (formData?.radius) {
      data.targeting.geo_locations = {
        custom_locations: {
          radius: formData.radius,
        },
      };
    }
  }

  return data;
}

function editGoogleFormData(campaign: BranchCampaignType, formData: any) {
  const data = {
    budget: campaign.budget,
    start_time: campaign?.start_time,
    stop_time: campaign?.stop_time ?? null,
    creative: {
      business_name: campaign?.creative?.business_name,
      business_location: {
        name: campaign?.creative?.business_location?.name,
        latitude: campaign?.creative?.business_location?.latitude,
        longitude: campaign?.creative?.business_location?.longitude,
        storefrontAddress: campaign?.creative.business_location?.storefrontAddress,
      },
      phone: campaign?.creative?.phone,
    },
    targeting: {
      geo_locations: campaign.targeting.geo_locations,
      locales: campaign.targeting.locales,
      keyword_themes: [],
      free_form_keyword_themes: campaign.targeting.keywords ?? [],
      negative_keywords: campaign.targeting.negative_keywords ?? [],
    },
    campaign_id: campaign.id,
  } as any;

  if (dateLib.isValid(formData?.stopTime)) {
    data.stop_time = formData.stopTime;
  }

  if (formData?.budget) {
    data.budget = String(formData.budget);
  }

  if (formData?.creative?.business_location) {
    data.creative.business_location = {
      name: formData.creative.business_location.name,
      latitude: formData.creative.business_location.latitude,
      longitude: formData.creative.business_location.longitude,
      storefrontAddress: formData.creative.business_location.storefrontAddress,
    };
  }

  if (formData?.keywords) {
    data.targeting = {
      ...data.targeting,
      keyword_themes: formData?.keyword_themes?.map((item: { value: string; label: string }) => ({
        id: item.value,
        text: item.label,
      })),
      free_form_keyword_themes: formData?.free_form_keyword_themes?.map((item: { value: string }) => item.value),
      negative_keywords: formData?.negative_keywords,
    };
  }

  if (formData.geoLocationType === GEO_LOCATION_TYPE.CITY) {
    if (formData?.cities) {
      data.targeting.geo_locations = {
        cities: formData.cities.map((city: { value: string; name: string; region: string; country_name: string }) => ({
          key: city.value,
          name: city.name,
          region: city.region,
          country_name: city.country_name,
          radius: 1,
          distance_unit: 'kilometer',
        })),
      };
    }
  }

  if (formData.geoLocationType === GEO_LOCATION_TYPE.RANGE) {
    if (formData?.radius) {
      data.targeting.geo_locations = {
        custom_locations: {
          radius: formData.radius,
        },
      };
    }
  }

  return data;
}

function createFormData(campaign: CampaignType, formData: any, branch: BranchType) {
  const MIN_BUDGET = '2000';
  const data = {
    budget: MIN_BUDGET,
    start_time: campaign?.start_time,
    stop_time: campaign?.stop_time ?? null,
    creative: {
      link_data: formData?.linkData,
    },
    targeting: {
      destination_type: formData.destinationType,
      targeting_type: formData.targetingType,
      gender: campaign.targeting.gender,
      age_min: campaign.targeting.age_min,
      age_max: campaign.targeting.age_max,
      geo_locations: {},
    },
    campaign_id: campaign.id,
  } as any;

  if (dateLib.isValid(formData?.startTime)) {
    if (dateLib.ISOtoJSDate(dateLib.now()) > dateLib.ISOtoJSDate(formData.startTime)) {
      data.start_time = dateLib.plusFromNow({ minutes: 2 });
    }

    data.start_time = formData?.startTime;
  } else {
    if (dateLib.ISOtoJSDate(dateLib.now()) > dateLib.ISOtoJSDate(campaign?.start_time)) {
      data.start_time = dateLib.plusFromNow({ minutes: 2 });
    }
  }

  if (dateLib.isValid(formData?.stopTime)) {
    data.stop_time = formData.stopTime;
  }

  if (formData?.budget) {
    data.budget = String(currency.reverse(formData.budget));
  }

  if (formData?.useAdSchedule) {
    const arrOfNum = formData?.selectedDays?.map((str: string) => {
      return Number(str);
    });

    data.creative = {
      ...data.creative,
      schedule: {
        start_at: dateLib.format(formData?.startScheduleHour, 'HH:mm'),
        end_at: dateLib.format(formData.stopScheduleHour, 'HH:mm'),
        days: arrOfNum,
      },
    };
  }

  if (formData?.gender) {
    data.targeting.gender = formData?.gender;
  }

  if (formData?.ageMin) {
    data.targeting.age_min = formData?.ageMin;
  }

  if (formData?.ageMax) {
    data.targeting.age_max = formData?.ageMax;
  }

  if (formData?.interests) {
    data.targeting = {
      ...data.targeting,
      ...interestsLib.groupByType(formData?.interests ?? []),
    };
  } else {
    const targetingData = data.targeting;
    delete targetingData.facebook_lead_gen_form_id;

    data.targeting = {
      ...campaign?.targeting,
      ...targetingData,
    };
  }

  if (campaign?.geo_location_type === GEO_LOCATION_TYPE.FREE) {
    data.targeting.geo_locations = {
      custom_locations: {
        radius: campaign.targeting?.default_location_range,
      },
    };
  }

  if (campaign?.geo_location_type === GEO_LOCATION_TYPE.CITY || formData.geoLocationType === GEO_LOCATION_TYPE.CITY) {
    data.targeting.geo_locations = {
      cities: [branch.default_facebook_city],
    };

    if (formData?.cities) {
      data.targeting.geo_locations = {
        cities: formData.cities.map((city: { value: string; name: string; region: string; country_name: string }) => ({
          key: city.value,
          name: city.name,
          region: city.region,
          country_name: city.country_name,
          radius: 0,
          distance_unit: 'kilometer',
        })),
      };
    }
  }

  if (campaign?.geo_location_type === GEO_LOCATION_TYPE.RANGE || formData.geoLocationType === GEO_LOCATION_TYPE.RANGE) {
    data.targeting.geo_locations = {
      custom_locations: {
        radius: campaign.targeting?.default_location_range,
      },
    };

    if (formData?.radius) {
      data.targeting.geo_locations = {
        custom_locations: {
          radius: formData.radius,
        },
      };
    }
  }

  return data;
}

function createGoogleFormData(campaign: CampaignType, formData: any, branch: BranchType) {
  const MIN_BUDGET = '50';
  const data = {
    budget: MIN_BUDGET,
    start_time: campaign?.start_time,
    stop_time: campaign?.stop_time ?? null,
    creative: {
      phone: branch?.phone_number,
      business_name: branch?.name,
      business_location: {
        name: formData.creative.business_location.value,
        latitude: formData.creative.business_location.latitude,
        longitude: formData.creative.business_location.longitude,
        storefrontAddress: formData.creative.business_location.storefrontAddress,
      },
    },
    targeting: {
      targeting_type: TARGETING_TYPES.SMART,
      geo_locations: {},
      locales: campaign.targeting.locales,
      keyword_themes: [],
      free_form_keyword_themes: campaign.targeting.keywords ?? [],
      negative_keywords: campaign.targeting.negative_keywords ?? [],
    },
    campaign_id: campaign.id,
  } as any;

  if (dateLib.isValid(formData?.startTime)) {
    if (dateLib.ISOtoJSDate(dateLib.now()) > dateLib.ISOtoJSDate(formData.startTime)) {
      data.start_time = dateLib.plusFromNow({ minutes: 2 });
    }

    data.start_time = formData?.startTime;
  } else {
    if (dateLib.ISOtoJSDate(dateLib.now()) > dateLib.ISOtoJSDate(campaign?.start_time)) {
      data.start_time = dateLib.plusFromNow({ minutes: 2 });
    }
  }

  if (dateLib.isValid(formData?.stopTime)) {
    data.stop_time = formData.stopTime;
  }

  if (formData?.budget) {
    data.budget = String(formData.budget);
  }

  if (formData?.creative?.business_location) {
    data.creative.business_location = {
      name: formData.creative.business_location.value,
      latitude: formData.creative.business_location.latitude,
      longitude: formData.creative.business_location.longitude,
      storefrontAddress: formData.creative.business_location.storefrontAddress,
    };
  }

  if (formData?.keywords) {
    data.targeting = {
      ...data.targeting,
      keyword_themes: formData?.keyword_themes?.map((item: { value: string; label: string }) => ({
        id: item.value,
        text: item.label,
      })),
      free_form_keyword_themes: formData?.free_form_keyword_themes?.map((item: { value: string }) => item.value),
      negative_keywords: formData?.negative_keywords,
    };
  }

  if (campaign?.geo_location_type === GEO_LOCATION_TYPE.FREE) {
    data.targeting.geo_locations = {
      custom_locations: {
        radius: campaign.targeting?.default_location_range,
      },
    };
  }

  if (campaign?.geo_location_type === GEO_LOCATION_TYPE.CITY || formData.geoLocationType === GEO_LOCATION_TYPE.CITY) {
    data.targeting.geo_locations = {
      cities: [branch.default_google_city],
    };

    if (formData?.cities) {
      data.targeting.geo_locations = {
        cities: formData.cities.map((city: { value: string; name: string; region: string; country_name: string }) => ({
          key: city.value,
          name: city.name,
          region: city.region,
          country_name: city.country_name,
          radius: 1,
          distance_unit: 'kilometer',
        })),
      };
    }
  }

  if (campaign?.geo_location_type === GEO_LOCATION_TYPE.RANGE || formData.geoLocationType === GEO_LOCATION_TYPE.RANGE) {
    data.targeting.geo_locations = {
      custom_locations: {
        radius: campaign.targeting?.default_location_range,
      },
    };

    if (formData?.radius) {
      data.targeting.geo_locations = {
        custom_locations: {
          radius: formData.radius,
        },
      };
    }
  }

  return data;
}

function campaignTypeNotAwailable(allowedObjectives: string[] | undefined, campaignTypes: string[]) {
  if (array.isEmpty(allowedObjectives)) {
    return true;
  }

  return !allowedObjectives?.some((type: string) => campaignTypes.includes(type));
}

function calculateCampaignReach(budget: number, dayCount: number) {
  const minUsersBound = ((budget * 1000) / CPM_VALUES.MIN_CPM) * Math.abs(dayCount);
  const avgUsersBound = ((budget * 1000) / CPM_VALUES.AVG_CPM) * Math.abs(dayCount);
  const maxUsersBound = ((budget * 1000) / CPM_VALUES.MAX_CPM) * Math.abs(dayCount);

  return { minUsersBound: minUsersBound, avgUsersBound: avgUsersBound, maxUsersBound: maxUsersBound };
}

function calculateCampaignScope(budget: number) {
  return ((budget / 3) * 1000).toFixed(2);
}

function calculateScopePercent(dailyScope: number, dailyEstimated: number) {
  return ((dailyScope * 100) / dailyEstimated).toFixed(2);
}

function handleKeywordByKeywordType(type: string, keywords: Array<{ keywordType: string }>) {
  return keywords?.filter((item: { keywordType: string }) => item.keywordType === type);
}

export default {
  getCampaignType,
  hasLeadGen,
  hasPermission,
  hasDefaultRange,
  defaultCity,
  createFormData,
  createGoogleFormData,
  editFormData,
  editGoogleFormData,
  campaignTypeNotAwailable,
  calculateCampaignReach,
  calculateCampaignScope,
  calculateScopePercent,
  handleKeywordByKeywordType,
};
